import React from 'react';
import styled from 'styled-components';
import { FaTwitter, FaAtom, FaMapMarkerAlt, FaQuoteLeft, FaQuoteRight, FaCheckCircle, FaLinkedin } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
`;

const ProfileSection = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const ProjectsSection = styled.div`
  flex: 3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  padding-bottom: 40px;
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;

  a {
    font-size: 24px;
    transition: color 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  .twitter-icon {
    color: #1DA1F2; // Twitter blue color
  }

  .linkedin-icon {
    color: #0077B5; // LinkedIn blue color
  }
`;

const ProjectCard = styled.div`
  background-color: white;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;

  &:hover {
    box-shadow: 0 6px 8px rgba(0,0,0,0.15);
  }
`;

const ProjectButton = styled.a`
  display: inline-block;
  background-color: #237fb7 ;
  color: white;
  padding: 8px 16px;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 10px;
`;

const StatusIndicator = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
  background-color: ${props => 
    props.$status === 'LIVE' ? '#4CAF50' :
    props.$status === 'BUILDING' ? '#FFA500' :
    props.$status === 'ACQUIRED' ? '#1E90FF' : '#808080'
  };
  color: white;
`;

const Headline = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  width: 100%;
`;

const MainContent = styled.div`
  display: flex;
`;

const MemoizedProjectCard = React.memo(({ project }) => (
  <ProjectCard>
    <h3><project.icon /> {project.title}</h3>
    <p>{project.description}</p>
    <meta name="description" content={`${project.title} - ${project.description}`} />
    <title>{`${project.title} | Arbi Oganesyan's Projects`}</title>
    {project.date && <p><strong>Started:</strong> {new Date(project.date).toLocaleDateString()}</p>}
    {project.status === 'ACQUIRED' && project.acquiredDate && (
      <p><strong>Acquired:</strong> {new Date(project.acquiredDate).toLocaleDateString()}</p>
    )}
    <div style={{ marginTop: 'auto' }}>
      <ProjectButton href={project.link} target="_blank" rel="noopener noreferrer">Visit Site</ProjectButton>
      <StatusIndicator $status={project.status}>{project.status}</StatusIndicator>
    </div>
  </ProjectCard>
));

const PersonalWebsite = () => {
  const [projects, setProjects] = React.useState([

    {
      id: 'cover-joy',
      title: 'Cover Joy',
      icon: FaAtom,
      description: 'Cover letters for your next job done right with AI. Paste resume, paste job description, get cover letter.',
      status: 'LIVE',
      link: 'https://coverjoy.xyz',
      date: '08-24-2024'
    },
    {
      id: 'sql-genie',
      title: 'SQL Genie',
      icon: FaAtom,
      description: 'Your Advanced AI-Powered SQL Assistant. Simply explain what you want to achieve in plain English and allow SQL Genie to write the fully query for you.',
      status: 'LIVE',
      link: 'https://sqlgenie.app',
      date: '08-20-2024'
    },     
    {
      id: 'onehopweb',
      title: 'OneHopWeb',
      icon: FaAtom,
      description: 'OneHopWeb is a web hosting company that provides affordable cPanel shared and reseller hosting.',
      status: 'ACQUIRED',
      link: 'https://www.prlog.org/12564727-blue-bell-web-hosting-llc-acquires-onehopwebcom.html',
      date: '07-10-2016',
      acquiredDate: '07-10-2016',
    },
    {
      id: 'evolans',
      title: 'Evolans LLC',
      icon: FaAtom,
      description: 'EVOLANS LLC is another gaming community dedicated to providing game server and web hosting services.',
      status: 'ACQUIRED',
      link: 'https://mergr.com/transaction/onehopweb-acquires-evolans',
      acquiredDate: '01-23-2008',
    },
    {
      id: 'unitedfrag',
      title: 'UnitedFrag',
      icon: FaAtom,
      description: 'UnitedFrag is a gaming community dedicated to providing game server and web hosting services on the internet.',
      status: 'ACQUIRED',
      link: 'https://mergr.com/transaction/onehopweb-acquires-unitedfrag',
      date: '09-07-2007',
      acquiredDate: '09-07-2007',
    },
  ]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(projects);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setProjects(items);
  };

  return (
    <Container>
      <Headline>Follow My Journey</Headline>
      <MainContent>
        <ProfileSection>
          <ProfileImage src="/img/me.jpg" alt="Profile" />
          <h1>Arbi</h1>
          <p><FaMapMarkerAlt /> Los Angeles, CA</p>
          <p><FaCheckCircle /> Ninja by day, entrepreneur by night. </p>
          <p>
            <FaQuoteLeft /> You don't choose your passions; your passions choose you. <FaQuoteRight />
          </p>
          <SocialIcons>
            <a href="https://twitter.com/arbioganesyan" target="_blank" rel="noopener noreferrer" className="twitter-icon">
              <FaTwitter />
            </a>
            <a href="https://www.linkedin.com/in/oganesyanarbi/" target="_blank" rel="noopener noreferrer" className="linkedin-icon">
              <FaLinkedin />
            </a>
          </SocialIcons>
        </ProfileSection>
        <ProjectsSection>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="projects" direction="horizontal">
              {(provided) => (
                <div 
                  {...provided.droppableProps} 
                  ref={provided.innerRef}
                  style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '30px'
                  }}
                >
                  {projects.map((project, index) => (
                    <Draggable key={project.id} draggableId={project.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <MemoizedProjectCard project={project} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ProjectsSection>
      </MainContent>
    </Container>
  );
};

export default PersonalWebsite;